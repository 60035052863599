import { ENTITY_TYPES, ROUTE_RESERVED_KEYWORDS } from 'operabase-router/constants';
import { ENTITY_TABS_TITLE, TP, ENTITY_VALID_TABS, ENTITY_MAIN_TABS } from 'constants/index';

import usePageContext from './usePageContext';

const shouldShowTab = ({ tab, stats = {}, entityType, mainPath, isCastingProfile }) => {
  if (mainPath && mainPath === tab) {
    return true;
  }

  switch (tab) {
    case ENTITY_MAIN_TABS.HOME: {
      return true;
    }
    case ENTITY_MAIN_TABS.CALENDAR: {
      return stats?.performances?.future?.exists;
    }
    case ENTITY_MAIN_TABS.PERFORMANCES: {
      switch (entityType) {
        case ENTITY_TYPES.PRODUCTION:
          return true;
        default:
          return stats?.productions?.exists || stats?.performances?.future?.exists;
      }
    }
    case ENTITY_MAIN_TABS.VIDEOS: {
      if (entityType === ENTITY_TYPES.PROFILE && !stats?.isPro) {
        return stats?.media?.videos?.digital?.exists;
      }

      return stats?.media?.videos?.exists || stats?.showreels?.intro?.exists;
    }
    case ENTITY_MAIN_TABS.IMAGES: {
      if (entityType === ENTITY_TYPES.PROFILE) {
        return stats?.isPro && stats?.media?.images?.exists;
      }
      return stats?.media?.images?.exists;
    }
    case ENTITY_MAIN_TABS.REVIEWS: {
      if (entityType === ENTITY_TYPES.PROFILE) {
        return stats?.isPro && stats?.reviews?.exists;
      }
      return stats?.reviews?.exists;
    }
    case ENTITY_MAIN_TABS.REPERTOIRE: {
      return isCastingProfile
        ? stats?.repertoire?.exists
        : stats?.isPro &&
            stats?.repertoire?.exists &&
            (stats?.customizations?.repertoire?.visibility || stats?.customizations?.futureRepertoire?.visibility);
    }
    case ENTITY_MAIN_TABS.ORG_GRAPH:
    case ENTITY_MAIN_TABS.ROLE_GRAPH:
    case ENTITY_MAIN_TABS.VENUE_GRAPH: {
      return stats?.repertoire?.exists;
    }
    case ENTITY_MAIN_TABS.BIO:
    case ENTITY_MAIN_TABS.ABOUT: {
      if (entityType === ENTITY_TYPES.PRODUCTION) {
        return true;
      }

      if (entityType === ENTITY_TYPES.ORGANIZATION) {
        return stats?.about?.exists || stats?.associatedOrgs?.exists || stats?.stages?.exists;
      }

      if (entityType === ENTITY_TYPES.PROFILE) {
        return isCastingProfile
          ? stats?.about?.exists
          : stats?.isPro && (stats?.about?.exists || stats?.personalInfo?.exists);
      }

      return stats?.about?.exists || stats?.personalInfo?.exists;
    }
    case ENTITY_MAIN_TABS.CONTACT: {
      return stats?.contacts?.exists || stats?.agencies?.exists;
    }
    case ENTITY_MAIN_TABS.WORKS: {
      return stats?.creations?.exists;
    }
    case ENTITY_MAIN_TABS.CAST_CREW: {
      return stats?.contributions?.exists;
    }
    case ENTITY_MAIN_TABS.VENUES: {
      return stats?.venues?.exists;
    }
    case ENTITY_MAIN_TABS.RENTALS: {
      return stats?.rentals?.exists;
    }
    case ENTITY_MAIN_TABS.INSIGHTS: {
      return true; // TODO: Check this condition
    }
    default: {
      return false;
    }
  }
};

export const useEntitySectionTabs = ({ entityType, entity, mainPath, baseRoute, filters }) => {
  const { navigate } = usePageContext();
  const isCastingProfile = ENTITY_TYPES.PROFILE === entityType && baseRoute === ROUTE_RESERVED_KEYWORDS.casting;
  let validTabs = ENTITY_VALID_TABS[entityType] || [];

  if (isCastingProfile) {
    validTabs = [
      ENTITY_MAIN_TABS.PERFORMANCES,
      ENTITY_MAIN_TABS.CALENDAR,
      ENTITY_MAIN_TABS.REPERTOIRE,
      ENTITY_MAIN_TABS.ORG_GRAPH,
      ENTITY_MAIN_TABS.ROLE_GRAPH,
      ENTITY_MAIN_TABS.VENUE_GRAPH,
      ENTITY_MAIN_TABS.BIO,
    ];
  }

  const { as, href, getSubPath } = navigate.getLinkProps({
    entity,
    entityType,
    baseRoute,
    filters,
    pro: isCastingProfile,
    skipAutoTab: true,
  });

  const getTabTitle = tab => {
    if (isCastingProfile) {
      if (tab === ENTITY_MAIN_TABS.PERFORMANCES) {
        return `${TP}.m_PERF`;
      }

      if (tab === ENTITY_MAIN_TABS.CALENDAR) {
        return `${TP}.FN_CALENDAR`;
      }
    }

    return ENTITY_TABS_TITLE[entityType][tab] || tab;
  };

  const getTabLinkProps = tab => {
    if (tab === ENTITY_MAIN_TABS.HOME || (isCastingProfile && tab === ENTITY_MAIN_TABS.PERFORMANCES)) {
      return { as, href, shallow: true };
    }

    return getSubPath({ path: tab, mergeFilters: true });
  };

  return validTabs
    .map(tab => ({
      title: getTabTitle(tab),
      key: tab,
      linkProps: getTabLinkProps(tab),
      showTab: shouldShowTab({ tab, stats: entity?.stats, entityType, isCastingProfile, mainPath }),
    }))
    .filter(tab => tab?.showTab);
};

export default useEntitySectionTabs;
