import React, { useMemo, useCallback } from 'react';

import SpriteIcon from 'components/uiLibrary/SpriteIcon';
import { PrimaryButton, TextButton } from 'components/uiLibrary/LinkButton';
import { COMPONENTS } from 'components/Globals/Analytics/constants';

import queries from 'containers/Accounts/queries';

import usePageContext from 'utils/hooks/usePageContext';
import { useDialogs } from 'utils/hooks/useDialogs';
import useEntityEditPermission from 'utils/hooks/useEntityEditPermission';
import { useNotificationPopup } from 'utils/hooks/useNotificationPopup';
import { useUserData } from 'utils/hooks/useAuthenticatedUser';
import { useQuery } from 'utils/react-query';
import { TP } from 'constants/index';
import { ENTITY_TYPES } from 'operabase-router/constants';

import { useTranslation } from 'src/i18n';

const useEntityEditPermissions = () => {
  const userData = useUserData();
  const { entityType, entity } = usePageContext();
  const { setIsEditDisclaimerModal } = useDialogs();
  const { data: registrations } = useQuery(
    queries.getRegistrations({
      userId: userData?.id,
      queryConfig: { enabled: !!userData?.id },
    }),
  );

  const hasRegistration = !!registrations?.data?.some(
    registration => registration?.entities?.profile?.id === entity?.id,
  );

  const disclaimerNotAccepted = !!registrations?.data?.some(
    registration =>
      registration?.entities?.profile?.id === entity?.id &&
      Number(registration?.step || 0) >= 8 &&
      !registration?.status?.has_accepted_disclaimer,
  );

  const { showEditBtn, ...rest } = useEntityEditPermission({
    entityType,
    entity,
  });
  const { canEdit, isVerificationPending } = rest || {};

  const { setPendingAccessPopup } = useNotificationPopup();
  const { isPreviewModalOpen } = useDialogs();

  const handleCheckPermissionPopup = useCallback(
    event => {
      if (disclaimerNotAccepted && hasRegistration && entityType === ENTITY_TYPES.PROFILE) {
        event.preventDefault();
        setIsEditDisclaimerModal(true);
        return;
      }
      if (canEdit) return;

      if (isVerificationPending) {
        event.preventDefault();
        setPendingAccessPopup(true);
      }
    },
    [
      canEdit,
      disclaimerNotAccepted,
      entityType,
      hasRegistration,
      isVerificationPending,
      setIsEditDisclaimerModal,
      setPendingAccessPopup,
    ],
  );

  return {
    ...rest,
    showEditBtn: showEditBtn && !isPreviewModalOpen,
    onEditClickHandler: handleCheckPermissionPopup,
  };
};

const EntityEditButton = ({ shape = 'rectangle', size, className, trackingData, asTextBtn = false }) => {
  const { t } = useTranslation('NS_APP_GLOBALS');
  const { navigate, entity, entityType } = usePageContext();

  const editLinkProps = useMemo(() => navigate.getLinkProps({ entity, entityType, edit: true, onlyLinkProps: true }), [
    entity,
    entityType,
    navigate,
  ]);

  const { showEditBtn, canEdit, onEditClickHandler } = useEntityEditPermissions();

  if (!showEditBtn) {
    return null;
  }

  const EditButton = asTextBtn ? TextButton : PrimaryButton;

  return (
    <EditButton
      styles={{ root: className }}
      leftIcon={<SpriteIcon icon="pencil" size={18} />}
      shape={shape}
      onClick={onEditClickHandler}
      trackingData={{
        ...trackingData,
        component: COMPONENTS.EDIT_CTA,
      }}
      {...editLinkProps}
      isLink={canEdit}
      preventDefault={!canEdit}
      size={size}
      rel="nofollow"
    >
      {t(entityType === ENTITY_TYPES.PROFILE ? `${TP}.FN_MANAGE_PROFILE` : `${TP}.m_EDIT`)}
    </EditButton>
  );
};

export { useEntityEditPermissions };
export default EntityEditButton;
