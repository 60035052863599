import React, { useState, useMemo, useEffect } from 'react';
import classnames from 'classnames';
import { useQuery } from 'utils/react-query';

import SpriteIcon from 'components/uiLibrary/SpriteIcon';
import Typography from 'components/uiLibrary/Typography';
import HorizontalScroller from 'components/uiLibrary/HorizontalScroller';
import LinkButton, { TextButton } from 'components/uiLibrary/LinkButton';
import Popover from 'components/uiLibrary/Popover';

import queries from 'containers/Productions/queries';

import usePageContext from 'utils/hooks/usePageContext';
import useDeviceTypeLayouts from 'utils/hooks/useDeviceTypeLayouts';
import { getBaseSeasonFilters } from 'utils/productions';
import useAppContext from 'utils/hooks/useAppContext';
import { createDate } from 'utils/date';
import { useTranslation } from 'src/i18n';

import { TP } from 'constants/index';
import { FILTER_TYPES, ROUTE_RESERVED_KEYWORDS } from 'operabase-router/constants';

import classes from './ContextualFilter.module.scss';

const Filters = () => {
  const { t } = useTranslation('NS_ENTITY_STUB_PAGE');
  const { navigate, entity: pageEntity, pathname } = usePageContext();
  const { obRouteContext } = useAppContext();
  const { linkProps, filterParams, page } = obRouteContext;
  const { entityType, entity } = page;
  const [anchorEl, setAnchorEl] = useState(null);
  const [activeFilter, setActiveFilter] = useState(null);
  const isPaidEntity = pageEntity?.stats?.isPro;

  const { data: pastSeasonList = [] } = useQuery(
    queries.getSeasons({
      filters: {
        ...getBaseSeasonFilters({
          entityType,
          entity,
          upcoming: false,
        }),
      },
      queryConfig: { enabled: true },
    }),
  );

  const types = [
    FILTER_TYPES.TICKET,
    FILTER_TYPES.WATCH_ONLINE,
    FILTER_TYPES.PAST_PERFORMANCE_COUNT,
    FILTER_TYPES.FUTURE_PERFORMANCE_COUNT,
  ];
  const { data: stats } = useQuery(
    queries.getFilterCounts({
      types,
      filters: filterParams,
      extraQueryKeys: [types],
      queryConfig: {
        enabled: true,
      },
    }),
  );

  const filters = useMemo(
    () => [
      {
        key: FILTER_TYPES.ONGOING_UPCOMING,
        label: `${TP}.FN_UPCOMING_PERFORMANCES`,
        count: stats?.[FILTER_TYPES.FUTURE_PERFORMANCE_COUNT],
        icon: 'calendar',
        getLinkProps: () => ({
          tab: ROUTE_RESERVED_KEYWORDS.performances,
          filters: {
            [FILTER_TYPES.ONGOING_UPCOMING]: true,
            [FILTER_TYPES.TICKET]: false,
            [FILTER_TYPES.WATCH_ONLINE]: false,
          },
        }),
      },
      {
        key: FILTER_TYPES.TICKET,
        label: `${TP}.FN_TICKETS`,
        count: stats?.[FILTER_TYPES.TICKET],
        icon: 'tickets',
        getLinkProps: () => ({
          tab: ROUTE_RESERVED_KEYWORDS.performances,
          filters: {
            [FILTER_TYPES.TICKET]: true,
            [FILTER_TYPES.ONGOING_UPCOMING]: false,
            [FILTER_TYPES.WATCH_ONLINE]: false,
          },
        }),
      },
      {
        key: FILTER_TYPES.WATCH_ONLINE,
        label: `${TP}.FN_WATCH_ONLINE_VIDEOS`,
        count: stats?.[FILTER_TYPES.WATCH_ONLINE],
        icon: 'play_circle_outline',
        getLinkProps: () => ({
          tab: ROUTE_RESERVED_KEYWORDS.performances,
          filters: {
            [FILTER_TYPES.WATCH_ONLINE]: true,
            [FILTER_TYPES.TICKET]: false,
            [FILTER_TYPES.ONGOING_UPCOMING]: false,
          },
        }),
      },
      {
        key: FILTER_TYPES.PAST_PERFORMANCE_COUNT,
        label: `${TP}.FN_PAST_PERFORMANCES`,
        count: stats?.[FILTER_TYPES.PAST_PERFORMANCE_COUNT],
        icon: 'calendar',
        getLinkProps: () => ({
          tab: ROUTE_RESERVED_KEYWORDS.performances,
          filters: {
            date: {
              date_to: createDate().format('YYYY-MM-DD'),
              path: ROUTE_RESERVED_KEYWORDS.past,
            },
            [FILTER_TYPES.ONGOING_UPCOMING]: false,
            [FILTER_TYPES.TICKET]: false,
            [FILTER_TYPES.WATCH_ONLINE]: false,
          },
        }),
      },
      ...pastSeasonList.slice(0, 5).map(season => ({
        key: FILTER_TYPES.DATE,
        label: season.name,
        count: season.perfCount,
        slug: season.slug,
        getLinkProps: year => ({
          tab: ROUTE_RESERVED_KEYWORDS.performances,
          filters: {
            date: {
              path: year,
              date_from: createDate(`${year}-01-01`).format('YYYY-MM-DD'),
              date_to: createDate(`${year}-12-31`).format('YYYY-MM-DD'),
            },
            [FILTER_TYPES.ONGOING_UPCOMING]: false,
            [FILTER_TYPES.TICKET]: false,
            [FILTER_TYPES.WATCH_ONLINE]: false,
          },
        }),
      })),
    ],
    [pastSeasonList, stats],
  );

  useEffect(() => {
    const selectedFilter = filters.find(filter => {
      if (filter.key === FILTER_TYPES.DATE) {
        return filter.slug === linkProps?.filters?.date?.path;
      }

      if (filter.key === FILTER_TYPES.PAST_PERFORMANCE_COUNT) {
        return pathname.includes(`/${ROUTE_RESERVED_KEYWORDS.past}`);
      }

      return Object.keys(linkProps?.filters).includes(filter.key);
    });
    setActiveFilter(selectedFilter);
  }, [filters, linkProps?.filters, pathname]);

  return (
    <>
      <nav>
        <ul className={classes.filters}>
          {filters.map(({ label, count, icon, getLinkProps, slug, key }) => {
            const props = getLinkProps(slug);
            const filterLinkProps = {
              ...props,
              tab: entity?.id ? props.tab : '',
              filters: {
                ...linkProps?.filters,
                ...props.filters,
              },
            };

            return (
              <li key={key} className={classes.filters__item}>
                <LinkButton
                  variant="text"
                  styles={{
                    root: classnames(classes.filters__btn, {
                      [classes.filters__btn_selected]:
                        key === FILTER_TYPES.DATE ? slug === activeFilter?.slug : key === activeFilter?.key,
                    }),
                    icon: classes.filters__icon,
                  }}
                  disableUnderline
                  isLink
                  onClick={() => setActiveFilter(key)}
                  {...navigate.getLinkProps({
                    entity,
                    entityType,
                    ...filterLinkProps,
                  })}
                  {...(icon && { leftIcon: <SpriteIcon icon={icon} size={16} /> })}
                >
                  <Typography size="12" weight="medium" className={classes.filters__label}>
                    {t(label)}
                  </Typography>
                  <Typography
                    className={classes.filters__count}
                    size={isPaidEntity ? '10' : '12'}
                    color={isPaidEntity ? 'white' : 'secondary'}
                  >
                    {count || 0}
                  </Typography>
                </LinkButton>
              </li>
            );
          })}
          {pastSeasonList.length > 6 && (
            <TextButton
              onClick={e => setAnchorEl(e.currentTarget)}
              styles={{ root: classes.filters__btn, icon: classes.filters__icon }}
              leftIcon={<SpriteIcon icon="calendar" size={16} />}
              rightIcon={<SpriteIcon icon="keyboard_arrow_down" size={16} />}
              disableUnderline
            >
              <Typography size="12" weight="medium" className={classes.filters__label}>
                {t(`${TP}.YEARS`)}
              </Typography>
            </TextButton>
          )}
        </ul>
      </nav>
      {anchorEl && (
        <Popover
          open
          anchorEl={anchorEl}
          onClose={() => setAnchorEl(null)}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          classes={{ paper: classes.popoverPaper }}
        >
          {pastSeasonList.map(({ id, name, perfCount, slug }) => {
            const filterLinkProps = {
              ...(entity?.id ? { tab: ROUTE_RESERVED_KEYWORDS.performances } : {}),
              filters: {
                date: {
                  ...linkProps?.filters,
                  path: slug,
                  date_from: createDate(`${slug}-01-01`).format('YYYY-MM-DD'),
                  date_to: createDate(`${slug}-12-31`).format('YYYY-MM-DD'),
                },
              },
            };

            return (
              <LinkButton
                key={id}
                variant="text"
                styles={{ root: classes.popoverItem }}
                disableUnderline
                isLink
                {...navigate.getLinkProps({
                  entity,
                  entityType,
                  ...filterLinkProps,
                })}
                onClick={() => setAnchorEl(null)}
              >
                <Typography size="12" weight="medium">
                  {name}
                </Typography>
                <Typography size="10" color="secondary">
                  {perfCount}
                </Typography>
              </LinkButton>
            );
          })}
        </Popover>
      )}
    </>
  );
};

const ContextualFilter = () => {
  const { isDesktop } = useDeviceTypeLayouts();
  return isDesktop ? (
    <Filters />
  ) : (
    <HorizontalScroller hideArrow>
      <Filters />
    </HorizontalScroller>
  );
};

export default ContextualFilter;
