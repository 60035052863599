/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useMemo } from 'react';
import dynamic from 'next/dynamic';

import SpriteIcon from 'components/uiLibrary/SpriteIcon';
import { TextButton, PrimaryButton } from 'components/uiLibrary/LinkButton';
import Image from 'components/uiLibrary/Image';
import Typography from 'components/uiLibrary/Typography';
import History from 'components/Globals/Layout/Header/History';
import ProfileDropdown from 'components/Globals/ProfileDropdown';
import ShareEntity from 'components/Globals/ShareEntity';
import HorizontalScroller from 'components/uiLibrary/HorizontalScroller';
// eslint-disable-next-line import/no-cycle
import FilterFacets, { FILTER_LAYOUT } from 'components/Filters/Display/FilterFacets';

import { SECTIONS, COMPONENTS, SUB_COMPONENTS } from 'components/Globals/Analytics/constants';

import queries from 'containers/Globals/queries';

import usePageContext from 'utils/hooks/usePageContext';
import useDeviceTypeLayouts from 'utils/hooks/useDeviceTypeLayouts';
import { useDialogs } from 'utils/hooks/useDialogs';
import useAppContext from 'utils/hooks/useAppContext';
import { getSearchStateFromFilters } from 'utils/search';
import { useQuery } from 'utils/react-query';
import { useTranslation } from 'src/i18n';

import { TP } from 'constants/index';
import { SEARCH_CATEGORIES, SEARCH_CATEGORIES_IDS } from 'constants/search';
import { ENTITY_TYPES } from 'operabase-router/constants';
import { NAV_LINKS } from 'constants/header';

import LogoImage from 'public/images/logo.png';

import classes from './StickyHeader.module.scss';

const ArtistIndexPageFilters = dynamic(() => import('components/Artist/Display/IndexPageFilters'));
const AgenciesIndexPageFilters = dynamic(() => import('components/Manager/Display/IndexPageFilters'));
const OrganizationIndexPageFilters = dynamic(() => import('components/Organization/Display/IndexPageFilters'), {
  ssr: false,
});

const INDEX_PAGE_FILTERS = {
  [ENTITY_TYPES.PROFILE]: ArtistIndexPageFilters,
  [ENTITY_TYPES.ORGANIZATION]: OrganizationIndexPageFilters,
  [ENTITY_TYPES.AGENCY]: AgenciesIndexPageFilters,
};

const Search = () => {
  const { t } = useTranslation('NS_APP_GLOBALS');
  const { obRouteContext } = useAppContext();
  const filters = obRouteContext?.linkProps?.filters;
  const categoryDetails = useMemo(() => getSearchStateFromFilters(filters), [filters]);

  const formatCategoryLabel = (category, details) => {
    if (!details) return t(category.label);

    const { selectedOptions, label, fromDate, toDate } = details;
    const isDateCategory = category.id === SEARCH_CATEGORIES_IDS.DATE;
    const isSelected = isDateCategory ? label || fromDate || toDate : selectedOptions?.length > 0;

    if (!isSelected) return t(category.label);

    return isDateCategory
      ? t(label) || `${fromDate} - ${toDate}`
      : selectedOptions?.map(option => option?.name)?.join(' ,');
  };

  return (
    <div
      className={classes.mobileSearch}
      onClick={() => {
        if (!window) {
          return;
        }

        window.scrollTo({ top: 0, behavior: 'smooth' });
      }}
    >
      <span className={classes.mobileSearch__searchSection}>
        <SpriteIcon icon="search" size="14" />
      </span>
      <Typography size="12" color="tertiary" weight="bold" truncate>
        {SEARCH_CATEGORIES.map(category => {
          const name = formatCategoryLabel(category, categoryDetails[category.id]);
          const isSelected = name !== t(category.label);

          return (
            <Typography key={category.id} size="12" color={isSelected ? 'secondary' : 'tertiary'} weight="bold">
              {name}
            </Typography>
          );
        }).reduce((elements, current, index) => (index === 0 ? [current] : [...elements, ' / ', current]), [])}
      </Typography>
    </div>
  );
};

const StickyHeaderContent = () => {
  const { t } = useTranslation('NS_APP_GLOBALS');
  const { obRouteContext } = useAppContext();
  const { url, page } = obRouteContext;
  const { entity, entityType } = page;
  const isHomePage = url === '/';
  const showIndexPageFilters =
    !entity?.id && [ENTITY_TYPES.PROFILE, ENTITY_TYPES.ORGANIZATION, ENTITY_TYPES.AGENCY].includes(entityType);
  const IndexPageFilters = INDEX_PAGE_FILTERS[entityType];

  const { data: statsNumbers } = useQuery(queries.getHomeNumbers());

  const navigationLinks = useMemo(
    () =>
      Object.entries(NAV_LINKS).map(([key, link]) => ({
        ...link,
        title: t(link.title),
        total: statsNumbers?.data?.[key]?.total || 0,
        type: key,
      })),
    [statsNumbers, t],
  );

  if (isHomePage) {
    return (
      <HorizontalScroller hideScrollbar>
        <div className={classes.links}>
          {navigationLinks.map((link, index) => (
            <TextButton
              styles={{ root: classes.links__item }}
              key={`${link.title}-${index}`}
              isLink
              disableUnderline
              href={link.as || link.path}
            >
              <Typography size="12" className={classes.link__label}>
                {link.title}
              </Typography>
              <Typography size="11" color="quinary">
                {link.total}
              </Typography>
            </TextButton>
          ))}
        </div>
      </HorizontalScroller>
    );
  }

  return (
    <div className={classes.stickyHeaderContent}>
      {showIndexPageFilters ? (
        <IndexPageFilters sticky />
      ) : (
        <FilterFacets layout={FILTER_LAYOUT.HORIZONTAL} scrollable />
      )}
    </div>
  );
};

const StickyHeader = ({ trackingData, isScrolledBeyond }) => {
  const { t } = useTranslation('NS_APP_GLOBALS');
  const { navigate, hideGlobalHeader, showGlobalHeader } = usePageContext();
  const { isLoggedIn } = useAppContext();
  const { isDesktop } = useDeviceTypeLayouts();
  const { setIsLoginDialog, setIsMenuOpen, setIsProToolsOpen } = useDialogs();
  const homePageLinkProps = navigate.getLinkProps({ onlyLinkProps: true });

  useEffect(() => {
    if (isScrolledBeyond) {
      hideGlobalHeader();
    } else {
      showGlobalHeader();
    }
  }, [isScrolledBeyond, hideGlobalHeader, showGlobalHeader]);

  if (!isScrolledBeyond) {
    return null;
  }

  if (!isDesktop) {
    return (
      <div className={classes.stickyHeader}>
        <div className={classes.stickyHeader__container}>
          <div className={classes.stickyHeader__top}>
            <Search />
            <ShareEntity
              showLabelText={false}
              className={classes.shareEntity}
              trackingData={{ section: SECTIONS.HEADER, component: COMPONENTS.SHARE_CTA }}
            />
          </div>
          <div className={classes.stickyHeader__bottom}>
            <StickyHeaderContent />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={classes.stickyHeader}>
      <div className={classes.stickyHeader__container}>
        <div className={classes.stickyHeader__top}>
          <div className={classes.stickyHeader__topLeft}>
            <TextButton
              onClick={() => setIsMenuOpen(true)}
              trackingData={{
                ...trackingData,
                subComponent: SUB_COMPONENTS.MENU,
              }}
              disableUnderline
              styles={{ root: classes.link }}
              leftIcon={<SpriteIcon icon="menu" size="14" className={classes.menuIcon} />}
            >
              <Typography color="secondary" size="12" weight="medium" className={classes.link__label}>
                {t(`${TP}.MENU`)}
              </Typography>
            </TextButton>
            <div className={classes.divider} />
            <TextButton
              onClick={() => setIsProToolsOpen(true)}
              styles={{ root: classes.link }}
              trackingData={{
                ...trackingData,
                subComponent: SUB_COMPONENTS.PRO_TOOLS_CTA,
              }}
              disableUnderline
              leftIcon={<SpriteIcon icon="keyboard_arrow_down" size="16" className={classes.menuIcon} />}
            >
              <Typography size="12" weight="medium" textTransform="uppercase" className={classes.link__label}>
                {t(`${TP}.PRO_TOOLS_FOR`)}
              </Typography>
            </TextButton>
            <div className={classes.divider} />
            <TextButton {...homePageLinkProps} isLink disableUnderline scroll>
              <Image
                src={LogoImage}
                alt="Operabase Home"
                lazy={false}
                className={classes.logoImage}
                disableCloudinary
                disableNextImage
              />
            </TextButton>
            <Search />
          </div>
          <div className={classes.stickyHeader__topRight}>
            <ShareEntity
              showLabelText={false}
              className={classes.shareEntity}
              trackingData={{ section: SECTIONS.HEADER, component: COMPONENTS.SHARE_CTA }}
            />
            <History sticky />
            {isLoggedIn ? (
              <ProfileDropdown />
            ) : (
              <PrimaryButton
                onClick={() => setIsLoginDialog({ isOpen: true })}
                shape="rectangle"
                leftIcon={<SpriteIcon icon="account_circle" size={14} />}
                size="medium"
                trackingData={{
                  ...trackingData,
                  subComponent: SUB_COMPONENTS.LOGIN_CTA,
                }}
              >
                {t(`${TP}.m_LOGIN`)}
              </PrimaryButton>
            )}
          </div>
        </div>
        <div className={classes.stickyHeader__bottom}>
          <StickyHeaderContent />
        </div>
      </div>
    </div>
  );
};

export default StickyHeader;
