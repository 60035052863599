/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useMemo } from 'react';
import classnames from 'classnames';
import useTracking from 'components/Globals/Analytics';

import Typography from 'components/uiLibrary/Typography';
import LinkButton from 'components/uiLibrary/LinkButton';
import SeparatorList from 'components/uiLibrary/SeparatorList';
import SpriteIcon from 'components/uiLibrary/SpriteIcon';

import { TP, PAYWALL_TYPES } from 'constants/index';
import { ENTITY_TYPES } from 'operabase-router/constants';

import usePageContext from 'utils/hooks/usePageContext';
import { useDialogs } from 'utils/hooks/useDialogs';
import useAppContext from 'utils/hooks/useAppContext';
import { TRACK_EVENTS, VIEW_IN_CASTING_TOOL } from 'utils/tracking';
import useDeviceTypeLayouts from 'utils/hooks/useDeviceTypeLayouts';
import { Trans, useTranslation } from 'src/i18n';

import classes from './CastingToolPromo.module.scss';
import { GOOGLE_OLD_TRACKING_SERVICES } from '../Analytics/constants';

const usePromoItems = ({ entityType, small }) => {
  const items = useMemo(() => {
    switch (entityType) {
      case ENTITY_TYPES.AGENCY: {
        return [`${TP}.m_CONTACT`, `${TP}.m_ACCOUNT_TYPES_AGENT`, `${TP}.m_REPRESENTATIONDETAIL`];
      }
      default: {
        return small
          ? [`${TP}.ADVANCED_SEARCH_FILTERS`, `${TP}.FN_CAREER_GRAPH`]
          : [
              `${TP}.ADVANCED_SEARCH_FILTERS`,
              'AGENCY_REPRESENTATION',
              `${TP}.FN_ARTIST_CONTACT_INFO`,
              `${TP}.FN_FUTURE_REPERTOIRE`,
              `${TP}.m_LISTREPERTOIRE`,
              `${TP}.FN_CAREER_GRAPH`,
              `${TP}.ROLE_GRAPH`,
              `${TP}.VENUE_GRAPH`,
            ];
      }
    }
  }, [entityType, small]);

  return items;
};

const CastingToolPromo = ({ trackingData, entityType, asCTA = false, styles, onClick, small = false }) => {
  const { t } = useTranslation('NS_ENTITY_STUB_PAGE');
  const track = useTracking();
  const { setPaywallType, obRouteContext } = useAppContext();
  const { isDesktop } = useDeviceTypeLayouts();
  const promoItems = usePromoItems({ entityType, small: small || !isDesktop });
  const { navigate, permissions } = usePageContext();
  const { isLoggedIn } = permissions;
  const { setIsLoginDialog } = useDialogs();
  const tabNames = useMemo(() => promoItems?.map(item => t(item)), [promoItems, t]);
  const linkProps = useMemo(
    () =>
      navigate.getLinkProps({
        ...obRouteContext?.linkProps,
        pro: true,
        tab: null,
      }),
    [navigate, obRouteContext?.linkProps],
  );

  const onClickHandler = event => {
    event.preventDefault();
    event.stopPropagation();

    const hasAccess = permissions?.castingToolPermissions?.hasAccess;

    track.click(
      {
        name: TRACK_EVENTS.CLICK,
        id: VIEW_IN_CASTING_TOOL,
      },
      GOOGLE_OLD_TRACKING_SERVICES,
    );

    if (onClick) {
      onClick(event);
    } else if (!isLoggedIn) {
      setIsLoginDialog({ isOpen: true });
    } else if (!hasAccess) {
      setPaywallType(PAYWALL_TYPES.CASTING_TOOL);
    } else {
      navigate.to(linkProps);
    }
  };

  if (asCTA) {
    return (
      <LinkButton
        variant="orange"
        size="medium"
        styles={{
          root: classnames(classes.castingToolCTA, {
            [styles?.castingToolCta]: !!styles?.castingToolCta,
          }),
        }}
        {...linkProps}
        preventDefault
        isV4
        rightIcon={<SpriteIcon icon="chevron_right" size={16} />}
        trackingData={trackingData}
        onClick={onClickHandler}
        isLink
        rel="nofollow"
      >
        <Trans
          ns="NS_ENTITY_STUB_PAGE"
          i18nKey={`${TP}.FN_CASTING_TOOL_PROMO_CTA`}
          components={{
            casting_tool_cta: <Typography size="12" weight="medium" className={classes.castingToolLabel} />,
          }}
        />
      </LinkButton>
    );
  }

  return (
    <div className={classes.root} onClick={onClickHandler}>
      <LinkButton
        variant="text"
        disableHover
        disableUnderline
        trackingData={trackingData}
        {...linkProps}
        isLink
        preventDefault
        rightIcon={<SpriteIcon icon="open_in_new" size={14} />}
        styles={{ root: classes.root__link }}
        rel="nofollow"
      >
        <Typography size="12" weight="bold" color="orange">
          {t(`${TP}.FN_CASTINGTOOL`)} & {t(`${TP}.TALENT_SEARCH`)}
        </Typography>
      </LinkButton>
      <div className={classes.root__divider} />
      <Typography size="12">
        <SeparatorList data={tabNames} styles={{ item: classes.root__separatorItem }} />
      </Typography>
    </div>
  );
};

export default CastingToolPromo;
